/// <reference path="../../typings/index.d.ts" />
/// <reference path="../../tools/typings/typescriptDemo.d.ts" />

module scriptsure.components {

	export class ReportContentDirective implements angular.IDirective {

		static $inject:string[] = [
			'$compile'
		];

		public restrict:string = 'A';
		public replace:boolean = false;

		constructor(private $compile:angular.ICompileService) {
		}

		public link =
			($scope:angular.IScope,
			 elm:any,
			 attrs:IReportContentAttributes,
			 ngModel:angular.INgModelController):void => {
				$scope.$watch(attrs.reportContent, (newValue:any, oldValue:any):void => {
					if (newValue) {
						elm.html(this.$compile(`<object flex data="${newValue}"></object>`)($scope));
					}
				});
			};

		static factory():angular.IDirectiveFactory {
			const directive = ($compile:angular.ICompileService) => new ReportContentDirective($compile);
			return directive;
		}
	}

	angular.module('scriptsure.components').directive('reportContent', ReportContentDirective.factory());

	export interface IReportContentAttributes extends angular.IAttributes {
		reportContent: string;
	}
}